import React, {useState, useEffect} from 'react'
import { Button, Select } from '.'
import api from '../services/api'

const LeaderResource = ({lesson, id}) => {
    const [resource, setResource] = useState(null)
    const [file, setFile] = useState(null)

    useEffect(()=>{
        api.getLeaderResource(id).then((resource)=>{
            setResource(resource);
        })
    },[])
    
    const onFileChange = (file)=>{
        const newFile = resource.files.find((f)=>f.type == file.title);
        setFile(newFile);
    }

    if (!resource){
        return null;
    }
    let isLink = "";
    let href = "";

    if (file){
        isLink = file.format.value == "link" || file.format.value == "vimeo";
        href = isLink ? (file.format.value == "vimeo" ? "/video/" + id: file.link) : (file.file ? file.file.permalink : '')
    
    }

    return <div class="border-gray-200 border bg-white">
        <div class="px-6 pt-8 flex justify-center bg-gray-200">
            <img style={{width: 350}} src={resource.preview_image ? resource.preview_image.url :require('../images/placeholder-document.png')}/>
        </div>
        <div class="px-8 py-6">
                <div class="mb-4">
                    <h3 class="text-lg mb-4">{resource.label}</h3>
                    <div class="text-sm">{resource.content}</div>
                </div>
                <div class="border-t pt-4 flex">
                        <div class="flex-1">
                            <Select onChange={onFileChange} 
                            after={
                            file ? <div class="pl-4">
                                {isLink ? <a href={href} target="_blank">
                                    <Button onClick={()=>{}} size="small" theme={lesson.theme}>Open</Button>
                                </a> : <a href={href} download={resource.label}>
                                    <Button onClick={()=>{}} size="small" theme={lesson.theme}>Download</Button>
                                </a>
                                }
                            </div> : null} 
                            label="Format" name="format" options={resource.files.map((file)=>{ return {title: file.type};})}/>
                        </div>
                </div>        
            </div>
    </div>
}
export default LeaderResource

