import React, {useState, useContext, useEffect} from 'react'
import { Wrapper} from '../components'
import api from '../services/api'
import { Button, Input, CopyBox, OffsetHeader } from '../components'
import themes from '../constants/themes';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const LeaderLogin = () => {
    const theme = themes['orange'];
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState(false)
    const auth = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(()=>{
        if (auth.user){
            navigate('/courses');
        }
    }, [auth.user])

    const login = () => {
        setLoginError(false);

        api.login({
            email, 
            password
        }).then(()=>{
            auth.loadUser();
        }).catch((data)=>{
            setLoginError(true);
        })
    }

    return <Wrapper mode="desktop">
         <OffsetHeader mode="desktop" expanded={true}>
                    <div class="flex items-center">
                        <div class="flex-1">
                            <img class="mr-4 w-64" src={require('../images/encircled.png')}/>
                        </div>
                    </div>
            </OffsetHeader>
            <CopyBox theme={theme}>
            Welcome back to Encircled. Login below to access your resources for helping your youth discover and live out their giftedness.</CopyBox>
                    <div class="max-w-lg">
                
        <CopyBox theme={theme}>
            <Input placeholder="" name="email" onChange={(val)=>{setEmail(val)}} label="Email"/>
            <Input type="password" placeholder="" onChange={(val)=>{setPassword(val)}} name="password" label="Password"/>
            {loginError ? <div class="text-xs mt-2 italic">Login info was invalid. Please try again.</div>: null}
        </CopyBox>
        <div class="flex">
            <div class="flex-1">
            <Button disabled={!email || !password} theme={theme} onClick={login}>Login</Button>

            </div>
        <Link class="underline text-gray-500 text-sm" to={"/forgot-password"}>Forgot Password?</Link>

        </div>

        </div>

    </Wrapper>
}

export default LeaderLogin