import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'

import { UserMenu, OffsetHeader, Wrapper, CourseLesson, PaymentGate} from '../../components'
import api from '../../services/api'

const CourseDetailLeader = () => {

    const [course, setCourse] = useState(null)

    const { courseSlug } = useParams()

    useEffect(()=>{
        api.getCourse(courseSlug).then((course)=>{
            setCourse(course);  
        })
    },[])

    if (!course){
        return null;
    }

    return <Wrapper mode="desktop"><PaymentGate>
            <OffsetHeader mode="desktop" expanded={true}>
                    <div class="flex items-center">
                        <div class="flex-1">
                            <img class="mr-4 w-64" src={course.logo.url}/>
                        </div>
                        <UserMenu/>
                    </div>
                <div class="text-center text-2xl font-bold">
                    Get started with this video.
                </div>

            </OffsetHeader>
            <div class="max-w-xl mx-auto mb-8">
                <div dangerouslySetInnerHTML={{__html: course.leader_intro_video}}/>
            </div>
            <div>
                {course.lessons.map((lesson)=>{
                    return <CourseLesson course={course} id={lesson.id}/>
                })}
            </div></PaymentGate>
        </Wrapper>
}

export default CourseDetailLeader