import { Fragment, useContext, useEffect} from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext';

const UserMenu = () => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext)

    useEffect(()=>{
      if (!auth.user){
          navigate('/leader/login');
      }
  }, [auth.user])

    const userNavigation = [
        { name: 'Logout', onClick: ()=>{
          auth.logout();
        } }
      ]

    return <Menu as="div" className="ml-3 relative">
    <div>
      <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600">
        <span className="sr-only">Open user menu</span>
        <span className={"inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-600"}>
            <span className={"font-bold leading-none text-white font-display"}>{auth.user.first_name[0]}{auth.user.last_name[0]}</span>
        </span>
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        {userNavigation.map((item) => (
          item.href || item.onClick ? 
          <Menu.Item key={item.name}>
            {({ active }) => (
              <a
                href={item.href}
                className={
                  (item.active ? 'bg-gray-100' : '') + 'block px-4 py-2 text-sm text-gray-700'
                }
                onClick={item.onClick}
              >
                {item.name}
              </a>
            )}
          </Menu.Item> : <div className="px-4 py-2">
              <p>{item.name}</p>
            </div>
        ))}
      </Menu.Items>
    </Transition>
  </Menu>
}

export default UserMenu


