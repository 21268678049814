const Button = ({children, onClick, theme, size, disabled, loading}) => {
    if (!size){
        size = "large";
    }
    if (loading){
        disabled = true;
    }
    if (!disabled){
        disabled = false;
    }
    const sizeClasses = size == "large" ? "py-3 px-8 text-lg" : "py-1 px-3 text-sm";
    return <button disabled={disabled} onClick={onClick} className={"tracking-wide rounded-full flex items-center " + sizeClasses + " " +  theme.button_classes + " " + (disabled && !loading ? "opacity-30":"")}>
        <>
        {children}
        {loading ? <img class="h-4 ml-1" src={require('../images/loading.gif')}/> : null}
        </>
    </button>
}

export default Button