import React, { useEffect, useState } from 'react'
import api from '../services/api'
import { ResourceItem } from '.';

const FeedbackItem = ({id, theme}) => {
    const [feedback, setFeedback] = useState(null)

    useEffect(()=>{
        api.getFeedback(id).then((feedback)=>{
            setFeedback(feedback);
        })
    },[])
    if (!feedback){
        return null;
    }

    return <div class="mt-12 mb-8 border-gray-200 border rounded p-8 bg-white relative">
    <h2 class="mb-4 text-xl font-display font-black  uppercase">{feedback.title}</h2>
    <div class="mb-4 text-justify">{feedback.content}</div>
    {feedback.resources.length > 0 ? <div class="mb-4 italic">Learn More...</div> : null}
    {feedback.resources.map((resource)=>{
       return <ResourceItem id={resource.id} theme={theme} />
    })}
</div>
}

export default FeedbackItem