import React, { useContext} from 'react'
import { AuthContext } from '../context/AuthContext';
import { Wrapper, OffsetHeader, CopyBox } from '.';
import themes from '../constants/themes';

const OrgSelector = ()=>{
    const auth = useContext(AuthContext)
    const theme = themes['orange'];
    return <Wrapper mode="desktop">
    <OffsetHeader mode="desktop" expanded={true}>
               <div class="flex items-center">
                   <div class="flex-1">
                       <img class="mr-4 w-64" src={require('../images/encircled.png')}/>
                   </div>
               </div>
       </OffsetHeader>
       <CopyBox theme={theme}>
       <h2 class="mb-4 text-xl font-display font-black  uppercase">What organization would you like to login as?</h2>
        {auth.user.organizations.map((organization)=>{
            return <div><a onClick={()=>{auth.updateOrganization(organization, true)}}>{organization.title}</a></div>;
        })}
       </CopyBox>

</Wrapper>
}
export default OrgSelector
