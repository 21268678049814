import React, { useEffect, useState, useContext } from 'react'
import {  Block, OffsetHeader, Header, Wrapper, ReportRecipients, Button} from '../../components';
import PWAPrompt from 'react-ios-pwa-prompt'

import { useParams } from 'react-router-dom'
import themes from '../../constants/themes';
import { ResponseContext } from '../../context/ResponseContext';
import { AuthContext } from '../../context/AuthContext';
import api from '../../services/api'
import Visibility from "visibilityjs";

const CoursePlanYouth = () => {
    const { planId } = useParams()
    const [course, setCourse] = useState('')
    const [contributorQuestionCount, setContributorQuestionCount] = useState(0)
    const [name, setName] = useState(null)
    const auth = useContext(AuthContext)

    const response = useContext(ResponseContext)

    useEffect(()=>{
        load();
        Visibility.change(function (e, state) {
            if (state =="visible"){
              load();
            }
        });
    },[])

    const load = () =>{
        api.getPlan(planId).then((plan)=>{
            response.populate(plan)
            setName(plan.user.first_name)
            api.getCourseById(plan.course_id).then((course)=>{
                setCourse(course);  
                setContributorQuestionCount(course.report_components.filter((reportComponent)=>reportComponent.type == 'contributor_question').length);
            })
        })  
    }

    function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }
    
    if (!course || response.answers.length == 0){
        return null;
    }

    const contributorAnswerCount = response.plan.plan_feedback.filter((feedback)=>feedback.user_id == auth.user.id).length;
    const isMine = auth.hasRole('Member')  && response.plan && (auth.user.id == response.plan.user_id); 
    return  <Wrapper mode="desktop">
        {!isMine ? <div class={"fixed bottom-0 bg-white w-full left-0 z-10 border-t-2 border-gray-200 pb-4 pt-2 px-4"}>
            <div class="max-w-5xl mx-auto px-5 lg:px-10">
            <div class="py-2 font-bold text-xs">YOU'VE COMPLETED {contributorAnswerCount} OF {contributorQuestionCount} QUESTIONS.</div>
            <div class="flex bg-gray-100">
            {[...Array(contributorQuestionCount)].map((e, i) => <div className={"flex-1 border  " + (contributorAnswerCount > i ? "bg-black" : "")} key={i}>&nbsp;</div>)}
            </div>
            </div>
        </div> : null}
        {auth.hasRole('Member') && iOS() ? <PWAPrompt copyBody={"Follow the instructions below to save your vision plan to your home screen."} /> : null}
        <div class="bg-gray-100 p-5">
        <OffsetHeader expanded={false} mode="desktop">
        <div class="flex items-center">
        <img class="mr-4 w-64" src={course.logo.url}/>
                <div>
                    <Header size="xl" theme={themes['black']}>Plan for {name}</Header>
                </div>
            </div>
        </OffsetHeader>
        <div class="bg-white p-8">
        {!isMine ? <div class="border-t-4 border-blue-600 bg-gradient-to-b bg-gray-100  text-blue-900 p-8 mb-8 wysiwyg" dangerouslySetInnerHTML={{__html: course.circle_member_intro}}/> : null}

        {course.report_components.map((component)=>{
            return <div><Block theme={themes['black']} component={component}/></div>
        })}
        </div>
        {isMine ? 
        <div class="bg-white p-8 mt-8 max-w-md print:hidden">
            <div class="mb-8 relative">
            <h2 class="uppercase text-2xl font-bold text-black">Your Circle</h2>
            <div class={"w-full h-1 bg-gradient-to-r absolute " + themes['black'].separator_classes}></div>
        </div>
        <ReportRecipients theme={themes['black']}/></div> : null}
    </div>
    </Wrapper>
    
}

export default CoursePlanYouth