import axios from 'axios'
let apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  withCredentials: true
});

export default {
  setOrganizationId(organizationId) {
    let headers = {
      Organization: organizationId,
    };
    apiClient = axios.create({
      baseURL: process.env.REACT_APP_API_BASE,
      withCredentials: true,
      headers
    });
  },
  verify(payload) {
    return apiClient.post('api/member/verify', payload).then((data) => data.data)
  },
  loginContributor(payload) {
    return apiClient.post('api/contributor/login', payload).then((data) => data.data)
  },
  loginMember(payload) {
    return apiClient.post('api/member/login', payload).then((data) => data.data)
  },
  deleteUserRole(id, role) {
    return apiClient.delete('api/user/' + id + '/' + role).then((data) => data.data)
  },
  login(payload) {
    return apiClient.post('login', payload).then((data) => data.data)
  },
  logout() {
    return apiClient.post('logout').then((response) => response)
  },
  resetPassword(payload){
    return apiClient.post('reset-password', payload);
  },
  forgotPassword(payload){
    return apiClient.post('forgot-password', payload);
  },
  updateMember(payload){
    return apiClient.put('api/member', payload).then((data) => data.data)
  },
  getUser(){
    return apiClient.get('api/user').then((data) => data.data)
  },
  getRoles(){
    return apiClient.get('api/user/roles').then((data) => data.data)
  },
  getUsers(params){
    return apiClient.get('api/users?' + new URLSearchParams(params).toString()).then((data) => data.data)
  },
  createLessonResponse(payload){
    return apiClient.post('api/lesson-response', payload).then((data) => data.data)
  },
  createContributor(payload){
    return apiClient.post('api/contributor', payload).then((data) => data.data)
  },
  getContributors(params){
    return apiClient.get('api/contributor?' + new URLSearchParams(params).toString()).then((data) => data.data)
  },
  getAnswers(params){
    return apiClient.get('api/response-answer?' + new URLSearchParams(params).toString()).then((data) => data.data)
  },
  createPlan(payload){
    return apiClient.post('api/plan', payload).then((data) => data.data)
  },
  createUser(payload) {
    return apiClient.post('api/user', payload).then((data) => data.data)
  },
  createOrganization(payload) {
    return apiClient.post('api/organization/create', payload).then((data) => data.data)
  },
  createPlanFeedback(payload){
    return apiClient.post('api/plan-feedback', payload).then((data) => data.data)
  },
  updateOrganization(payload) {
    return apiClient.put('api/organization', payload).then((data) => data.data)
  },
  getOrganization() {
    return apiClient.get('api/organization').then((data) => data.data)
  },
  getPlan(id, params){
    return apiClient.get('api/plan/' + id + '?' + new URLSearchParams(params).toString()).then((data) => data.data)
  },
  getPlans(params){
    return apiClient.get('api/plan/?' + new URLSearchParams(params).toString()).then((data) => data.data)
  },
  getLesson(slug) {
    return apiClient.get('cms/collections/lessons/entries/?filter[slug:is]=' + slug).then((response) => response.data.data[0])
  },
  getLessonById(id) {
    return apiClient.get('cms/collections/lessons/entries/' + id).then((response) => response.data.data)
  },
  cookie(){
    return apiClient.get('sanctum/csrf-cookie');
  },
  getCourses() {
    return apiClient.get('cms/collections/courses/entries').then((response) => response.data.data)
  },
  getCourse(slug) {
    return apiClient.get('cms/collections/courses/entries/?filter[slug:is]=' + slug).then((response) => response.data.data[0])
  },
  getCourseById(id) {
    return apiClient.get('cms/collections/courses/entries/' + id).then((response) => response.data.data)
  },
  getQuestion(id) {
    return apiClient.get('cms/collections/questions/entries/' + id).then((response) => response.data.data)
  },
  getContributorQuestion(id) {
    return apiClient.get('cms/collections/contributor_questions/entries/' + id).then((response) => response.data.data)
  },
  getFeedback(id) {
    return apiClient.get('cms/collections/feedback/entries/' + id).then((response) => response.data.data)
  },
  getStudentResource(id) {
    return apiClient.get('cms/collections/student_resources/entries/' + id).then((response) => response.data.data)
  },
  getLeaderResource(id) {
    return apiClient.get('cms/collections/leader_resources/entries/' + id).then((response) => response.data.data)
  },
  
}
