import React, { createContext, useEffect, useMemo, useState} from 'react'
import { useLocation } from 'react-router-dom'
import api from '../services/api'
import { useNavigate } from 'react-router-dom'

export const AuthContext = createContext({})

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const AuthProvider = (props) => {
  let query = useQuery();
  const { children } = props
  const [user, setUser] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [ready, setReady] = useState(false)
  const [roles, setRoles] = useState([])
  const navigate = useNavigate();

  useEffect(()=>{
    if (query.get('token')){
      api.cookie().then(()=>{
        localStorage.setItem('encircled_org_id', query.get('organization_id'));
        api.loginContributor({token: query.get('token')}).then(()=>{
          loadUser();
        })
      })
    } else if (query.get('session_id')){
      api.setOrganizationId(query.get('organization_id'));
      api.updateOrganization({session_id: query.get('session_id')}).then(()=>{
        loadUser();
      })
    } else {
      loadUser();
    }
  }, [])

  const loadUser= ()=> {
    setReady(false);
    api.getUser().then((user)=>{
      setUser(user);
    }).catch(()=>{
      setUser(null);
      setReady(true);
    });
  }

  useEffect(()=>{
    if (user){
      if (user.organizations.length == 1){
        updateOrganization(user.organizations[0], true)
      } else if (localStorage.getItem('encircled_org_id')){
        let organization = user.organizations.find((org)=>org.id == localStorage.getItem('encircled_org_id'));
        if (organization){
          updateOrganization(organization, false)
        } else {
          localStorage.removeItem('encircled_org_id');
          setReady(true);
        }
      } else {
        setReady(true);
    }
  }
}, [user])

  const updateOrganization = (organization, setLocalStorage)=>{
    setReady(false);

    if (setLocalStorage){
      localStorage.setItem('encircled_org_id', organization.id);
    }
    api.setOrganizationId(organization.id);
    setOrganization(organization);
  }

  useEffect(()=>{
    if (organization){
      api.getRoles().then((roles)=>{
        setRoles(roles.map((role)=>role.name)); 
        setReady(true); 
      })
    }
  }, [organization])
  
  const logout =() => {
    setReady(false);
    localStorage.removeItem('encircled_org_id');
    api.logout().then(()=>{
      setOrganization(null);
      setUser(null);
      setReady(true);
      window.location.reload();
    });
  }

  const hasRole = (xRoles)=> {
    return Array.isArray(xRoles) ? (xRoles.filter(element => roles.includes(element))).length > 0 : roles.indexOf(xRoles) > -1;
  }

  const value = useMemo(() => {
    return {
      user,
      ready,
      organization,
      logout,
      loadUser,
      hasRole,
      updateOrganization
    }
  }, [user, ready, organization, roles])

  return (
    <AuthContext.Provider value={value} {...props}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
