import React, {useState} from 'react'
import { FaRegEye } from "react-icons/fa";
import { toast } from 'react-toastify';

const Scripture = ({reference, version, content, theme}) => {
    const [open, setOpen] = useState(false)

    const Msg = ({ closeToast, toastProps }) => (
        <>
            <div class="font-bold text-lg font-display mb-2">{content}</div>
            <div class="italic">{reference} ({version})</div>
        </>
    ) 
 
    return <div>
        {open ? <div class="fixed z-10 opacity-30 bg-black h-full w-full top-0 left-0" onClick={()=>{toast.dismiss()}}></div> : null}
        <button class={"flex items-center tracking-wide rounded-full border py-1 px-3 " + theme.button_classes} onClick={()=>{
            toast(<Msg />, { onOpen: ()=>{
                setOpen(true);
            }, onClose: ()=>{
                setOpen(false);
            }, position: toast.POSITION.BOTTOM_CENTER, autoClose: false, bodyClassName: '!p-5', className: "border-t-4 border-gray-400"})
        }}>
                <span class="mr-1">{reference}</span><FaRegEye/>
            </button>
    </div>
}

export default Scripture