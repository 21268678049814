import React, { useContext, useEffect, useState } from 'react'
import { ResponseContext } from '../../context/ResponseContext'
import { Button } from '..'
import api from '../../services/api'
import { AuthContext } from '../../context/AuthContext';

const PlanLinkBlock = ({component, theme}) => {
    const [token, setToken] = useState(null)
    const auth = useContext(AuthContext)

    useEffect(()=>{
        api.getPlan(response.plan.id, {getToken: 1}).then((plan)=>{
            setToken(plan.token)
        })
    },[])
    const response = useContext(ResponseContext)
    if (!token){
        return null;
    }

    return <div class="mb-8"><Button theme={theme} onClick={()=>{
        window.open('/plan/' + response.plan.id + '?token=' + token + '&organization_id=' + auth.organization.id);
    }}>View Your Vision Plan</Button></div>
}

export default PlanLinkBlock