
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip
  );

const Graph = ({title, labels, data}) => {
    return <div class="max-w-sm p-5 mb-4 border-gray-200 border bg-white"><div class="text-sm mb-4">
       {title}
    </div>
    <Bar
      options={{
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }
        }
      }}
    data= {{
        labels: labels,
        datasets: [
          {
            data: data,
          },
        ],
      }}
    
    />
    
    </div>
}

export default Graph