import React, { useEffect, useState, useContext } from 'react'
import api from '../../services/api'
import { QuestionBox, Question, TextArea, Button } from '..';
import { useParams } from 'react-router-dom'
import { ResponseContext } from '../../context/ResponseContext'
import { AuthContext } from '../../context/AuthContext';
import { FaCheckCircle } from 'react-icons/fa';

const ContributorQuestionBlock = ({component, theme}) => {
    const response = useContext(ResponseContext)
    const auth = useContext(AuthContext)

    const [question, setQuestion] = useState(0)
    const [answer, setAnswer] = useState('')
    const [hasAnswered, setHasAnswered] = useState(false)
    const [isAdding, setIsAdding] = useState(false)

    const [answers, setAnswers] = useState(null)

    const { planId } = useParams()

    useEffect(()=>{
        setHasAnswered(response.getContributorHasAnsweredQuestion(auth.user, component.question.id));
    },[response.contributorAnswers])

    useEffect(()=>{
        api.getContributorQuestion(component.question.id).then((question)=>{
            setQuestion(question);
        })
    },[])

    useEffect(()=>{
        setAnswers(response.getContributorAnswerByQuestionId(component.question.id));
        api.getContributorQuestion(component.question.id).then((question)=>{
            setQuestion(question);
        })
    },[response.contributorAnswers])

    const submit = () =>{
        setIsAdding(true);
        api.createPlanFeedback({
            plan_id: planId,
            question_id: question.id,
            content: answer
        }).then(()=>{
            api.getPlan(planId).then((plan)=>{
                response.populate(plan)
                setIsAdding(false);

            });
        })
    }

    if (!question){
        return null;
    }
    const isMine = auth.hasRole('Member') && response.plan && (auth.user.id == response.plan.user_id); 

    return <div class="relative my-8">
        {!isMine ? <div class="max-w-md py-6 px-8 rounded mb-4 border shadow print:hidden">
        <div>
            <div class={"mb-2 font-bold " + theme.text_classes}>{question.title} {hasAnswered ?<FaCheckCircle class="ml-2 inline"/> : null}</div>        
            <div class="text-gray-700 text-sm italic">{question.helper_text}</div>      
        </div>
            {hasAnswered ? null : 
            <div class="mt-4">
                <div class="mb-2"><TextArea classes="bg-gray-100 border-0 rounded" onChange={setAnswer} /> </div>
            <Button size="small" loading={isAdding} disabled={!answer}  onClick={submit} theme={theme}>Add</Button></div> }
        </div> : null}
        <div>
        { answers ? <div class="mb-6">
            <div class="font-bold pb-2 mb-2">{component.plan_intro}</div>
            <div class="bg-gray-50 p-4">
            {answers.map((answer)=>{
                return <div>{answer.content} - {answer.user.first_name}</div>
            })}

            </div>
        </div> : null}
        </div>
    </div>
}

export default ContributorQuestionBlock