import React from 'react'
import { Wrapper } from '../components'
import {Bar} from 'react-chartjs-2';
const state = {
    labels: ['January', 'February', 'March',
             'April', 'May'],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }

const Week1LeaderResponse = () => {
    return <> 
            <div class="my-8 flex items-center">
                <h1 class="text-3xl font-bold flex-1">Identify Who I Am</h1>
                <div class="h-24 w-24 bg-gray-200 flex justify-center items-center">Icon</div>
            </div>
                <div class="text-lg mb-4">
                    What messages most stood out?
                </div>
                <Bar
                    data={state}
                    options={{
                        title:{
                        display:true,
                        text:'Average Rainfall per month',
                        fontSize:20
                        },
                        legend:{
                        display:true,
                        position:'right'
                        }
                    }}
                    />
                
        </>
}

export default Week1LeaderResponse