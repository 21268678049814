import { Header } from '../components';
import OffsetHeader from './OffsetHeader';

const LessonHeader = ({lesson, userName, page, totalPages, mode}) => {
    const getIntroMessage = ()=>{
        return lesson.intro_message.replace('[name]', '<span class="' + lesson.theme.text_classes + ' font-bold">' + userName + '</span>');
    }

    let expanded = (page == 0) || (page == totalPages-1);
    return <OffsetHeader expanded={expanded} mode={mode}>
        <div class="flex items-center">
            <img class={"mr-4 " + (expanded ? "w-24": "w-12")} src={lesson.icon.url}/>
            <div>
                {page == 0 && lesson.intro_message && userName ? <div class="mb-2" dangerouslySetInnerHTML={{__html: getIntroMessage()}}/> : null}
                <Header size={(expanded ? "3xl": "xl")} theme={lesson.theme}>{lesson.title}</Header>
                {expanded ? <h2 class="text-lg font-light">Session {lesson.number}{page == totalPages -1 ? " Summary" : ""}</h2> : null}
            </div>
        </div>
</OffsetHeader>
}

export default LessonHeader