import { Button } from '../../components'

const CtaBlock = ({component, theme}) => {
    if (!component.copy){
        return <div class="text-center mb-8"><a class="inline-block" target="_blank" href={component.url}><img src={component.image.url}/></a></div>;
    }
    return  <div class="mt-12 mb-8 border-gray-200 border rounded p-8 bg-white relative">
    <h2 class="mb-4 text-xl font-display font-black  uppercase">{component.title}</h2>
    {component.image ? <a target="_blank" href={component.url} class="mr-3"><img src={component.image.url}/></a> : null}
    <div class="my-4 text-justify">{component.copy}</div>
    <a target="_blank" href={component.url}><Button theme={theme}>Go</Button></a>
    </div>
}

export default CtaBlock