import { FaExclamationCircle } from 'react-icons/fa';
import { Button } from '../components';

const Pagination = ({setPage, page, totalPages, skip, finalLabel, theme, nextReady, onComplete, nextNotReadyText}) => {
    if (!finalLabel){
        finalLabel = "Next";
    }
    if (page == totalPages-1){
        return null;
    }
    return <>
        {nextNotReadyText ? <div class="bg-white italic text-sm p-4 border-2 mb-4 flex">
            <FaExclamationCircle class="h-8 w-8 mr-4"/>
            <div dangerouslySetInnerHTML={{__html: nextNotReadyText}}/>
        </div> : null}
    
    <div class="flex pb-8">
    <div class="flex-1">
        {page !=0 && page <= (totalPages-2) && <Button theme={theme} onClick={()=>{setPage(page-1)}}>Back</Button>}                
        </div>
        {skip && <Button theme={theme} classes="mr-4" onClick={()=>{setPage(page+1)}}>Skip</Button>}
        {(page < (totalPages-2)) && <Button disabled={!nextReady} theme={theme} onClick={()=>{setPage(page+1)}}>{page == 0 ? "Get Started" : "Next"}</Button>}
        {(page == (totalPages-2)) && <Button theme={theme} onClick={()=>{setPage(page+1); onComplete();}}>{finalLabel}</Button>}
    </div>
    </>
}

export default Pagination