import { CopyBox } from '../../components';

const QuoteBlock = ({component, theme}) => {
    return <CopyBox theme={theme}>
        <div class="relative p-6">
            <div class="text-5xl font-bold absolute left-0 top-0">“</div>
            <div class="font-bold text-xl" dangerouslySetInnerHTML={{__html: component.copy}}/>
            <div class="text-5xl absolute font-bold right-0">”</div>
        </div>
        {component.quote_reference ? <div class="text-sm text-right uppercase mt-4">{component.quote_reference}</div> : null}
    </CopyBox>
}

export default QuoteBlock