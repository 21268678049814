import React, { useState } from 'react'
import { Navigate } from 'react-router'
import * as Yup from "yup";
import { useForm } from "react-yup";
import api from '../services/api';
import { Input, Button, Wrapper, OffsetHeader, CopyBox } from '../components';
import { toast } from 'react-toastify';
import themes from '../constants/themes';

// Define your Yup schema
const schema = Yup.object({
    email: Yup.string().email().required()
  }).defined();
const ForgotPassword = () => {
    const [isSent, setIsSent] = useState(false)
    const theme = themes['orange'];

    const {
        values,
        touched,
        errors,
        field,
        createSubmitHandler,
      } = useForm({
        validationSchema: schema,
      });

    const handleSubmit = React.useMemo(() => {
        return createSubmitHandler(
            (values) => {
                api.forgotPassword(values).then(()=>{
                  setIsSent(true);
                  toast.success('You have been emailed a link to reset your password.')
                }).catch((e)=>{
                  toast.error(e.response.data.message);
                });
            });
    }, []);

    if (isSent){
      return <Navigate to={`/leader/login`} />
    }

    return <Wrapper mode="desktop">

    <OffsetHeader mode="desktop" expanded={true}>
                <div class="flex items-center">
                    <div class="flex-1">
                        <img class="mr-4 w-64" src={require('../images/encircled.png')}/>
                    </div>
                </div>
        </OffsetHeader>
        <CopyBox theme={theme}>
            Request a password reset below.
        </CopyBox>
        <div class="max-w-lg">
        <form action="#" method="POST" onSubmit={handleSubmit}>
            <CopyBox theme={theme}>
                <Input name="email" placeholder="name@example.com"  label="Your email" field={field}/>
            </CopyBox>
            <Button theme={theme}>Send Reset Link</Button>
        </form>
        </div>
      </Wrapper>
}
export default ForgotPassword
