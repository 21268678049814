import React, {useState, useEffect} from 'react'
import { LeaderResource, LessonHeader, CopyBox, Wrapper, PaymentGate } from '../../components'
import api from '../../services/api'
import { useParams } from 'react-router-dom'

const LessonDetailLeader = () => {

    const [lesson, setLesson] = useState(null)

    const { lessonSlug } = useParams()

    useEffect(()=>{
        api.getLesson(lessonSlug).then((lesson)=>{
            setLesson(lesson);  
        })
    },[])

    if (!lesson){
        return null;
    }

    return <Wrapper mode="desktop"><PaymentGate>
            <LessonHeader mode="desktop" lesson={lesson} page={0} totalPages={0}/>
            <div class="flex">
                    <CopyBox theme={lesson.theme}>
                    <div class="flex items-center">
                        <div class="flex-1 text-sm" dangerouslySetInnerHTML={{__html: lesson.leader_intro}}/>
                        <div class="flex-1 ml-12" dangerouslySetInnerHTML={{__html: lesson.leader_intro_video}}/>
                    </div>
                    </CopyBox>
            </div>
            {lesson.resource_groups.map((group)=>{
                return <div class="pb-8">
                    <h2 class="text-2xl mb-4 font-display font-semibold tracking-wider">{group.title}</h2>
                    <div class="grid grid-cols-2 gap-4">
                    {group.resources.map((r)=>{
                        return <LeaderResource id={r.id} lesson={lesson}/>
                    })}
                    </div>    
                </div>
            })}</PaymentGate>
        </Wrapper>
}

export default LessonDetailLeader