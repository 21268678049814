import React, {useState, useContext, useEffect} from 'react'
import { Input, Button, ErrorMessage} from '../../components'
import api from '../../services/api';
import { ResponseContext } from '../../context/ResponseContext'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import * as Yup from "yup";
import { useForm } from "react-yup";
import { toast } from 'react-toastify';

const ReportRecipients = ({theme}) => {
    const response = useContext(ResponseContext)
    const [contributors, setContributors] = useState([])
      useEffect(()=>{
        loadContributors();
      },[])

    const loadContributors = () =>{
        api.getContributors({
            plan_id: response.plan.id
        }).then((contributors)=>{
            setContributors(contributors);
        })
    }

    return <div>
        <div class="text-left"> 
            <AddForm key={contributors.length + 1} theme={theme} loadContributors={loadContributors}/>
        </div>
        {contributors.length > 0 ?
        <table class="min-w-full text-left mt-8">
                <thead>
                    <tr class="border-b border-gray-200">
                    <th class="uppercase text-sm text-gray-400">Name</th>
                    <th class="uppercase text-sm text-gray-400">Email</th>
                    <th class="uppercase text-sm text-gray-400">Phone</th>
                    </tr>
                </thead>
        {contributors.map((contributor, key)=>{
                return <tr>
                        <td class="py-4 pr-2 text-sm">{contributor.first_name}</td>
                        <td class="py-4 pr-2 text-sm">{!contributor.phone_number ? contributor.email :  ''}</td>
                        <td class="py-4 pr-2 text-sm">{contributor.phone_number}</td>
                    </tr>
        })
        }
        </table> : null}
    </div>
           
}

const schema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    mode: Yup.string().required(),
    email: Yup.string().when('mode', {
        is: 'email',
        then:  Yup.string().required('Email is required.'),
        otherwise: Yup.string()
    }),
    phone_number: Yup.string().when('mode', {
        is: 'phone',
        then:  Yup.string().required('Phone Number is required.'),
        otherwise: Yup.string()
    })
  }, [['email', 'phone_number']]);


const AddForm = ({theme, loadContributors}) => {
    const response = useContext(ResponseContext)
    const [loading, setLoading] = useState(false)
    const {
        touched,
        errors,
        field,
        createSubmitHandler,
        setValue,
        setTouched,
        validateField, 
        getValues,
        values,
        setValues,
        resetErrors
      } = useForm({
        validationSchema: schema,
      });

      useEffect(()=>{
        setValue('mode', 'phone');
      },[])
      
    const handleSubmit = React.useMemo(() => {
        return createSubmitHandler(
            (values) => {
                setLoading(true);
                api.createContributor({
                   ...values,
                    name: values.name,
                    phone_number: values.mode == 'phone' ?  values['phone_number'] : '',
                    email: values.mode == 'email' ?  values['email'] : '',
                    plan_id: response.plan.id
                }).then(()=>{
                    setLoading(false);
                    loadContributors();
                 }).catch((e)=>{
                     setLoading(false);
                    toast.error(e.response.data.message);
                });
            });
     }, []);

    return  <form onSubmit={handleSubmit}>
        <Input field={field} inputClasses="-mt-1" name="name" label="Name"/>
        <ErrorMessage error={errors.name} touched={touched.name}/>
        <div class="flex items-center">
            <button className={"mr-3 text-sm " + (values.mode=="phone" ? "border-b-2 border-black": "") } onClick={(e)=>{
                e.preventDefault();
                setValue('mode','phone');                    
            }} >
            <label htmlFor="phone_number" className="">
                Phone Number
            </label></button>
            <div class="mr-3 font-light text-xs">OR</div>
            <button className={"text-sm " + (values.mode=="email" ? "border-b-2 border-black": "") } onClick={(e)=>{
                e.preventDefault();
                setValue('mode','email');
            }}>
            <label htmlFor="email" className="">
                Email
            </label>
            </button>
        </div>

        <PhoneInput
            name="phone_number"
            className={"mt-4 mb-4 py-3 px-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 " + (values.mode == "email" ? "!hidden" : "")}
            addInternationalOption = {false}
            defaultCountry={"US"}
            countries={["US", "CA", "MX"]}
            onBlur={(e)=>{
                if (!e.target.value){
                    setValue('phone_number', '');
                }
                setTouched(touched => {
                    touched['phone_number'] = true;
                    return touched;
                });
                validateField('phone_number', getValues())
            }}
            onChange={(val)=>{
                setValue('phone_number', val)

            }}
        />
        <div className={values.mode == "phone" ? "hidden" : ""}>
        <Input field={field} name="email" label={null}/>
        </div>

        {values.mode == "phone" ? <ErrorMessage error={errors['phone_number']} touched={touched['phone_number']}/> : <ErrorMessage error={errors.email} touched={touched.email}/>}
        <Button loading={loading} theme={theme}>Invite</Button>    
    </form>
}

export default ReportRecipients