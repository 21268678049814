import Scripture from "./Scripture"

const CheckboxGroup = ({name, options, onChange, value, theme}) => {
    return <fieldset>
        <legend className="sr-only">{name}</legend>
        <div className="border-t border-b border-gray-200 divide-y divide-gray-200">
        {options.map((option) => (
            <div key={option.title} className="relative flex items-center py-4">
             <div className="ml-3 flex items-center h-5">
                <input
                    id={`${name}-${option.title}`}
                    name={`${name}-${option.title}`}
                    type="checkbox"
                    onClick={(e)=>{onChange(option)}}
                    className={" h-4 w-4 border-gray-300 rounded "  + theme.checkbox_classes}
                    checked={value.indexOf(option.title) > -1}
                />
            </div>
            <div className="min-w-0 flex-1 text-sm ml-4">
                <label htmlFor={`${name}-${option.id}`} className="select-none flex items-center">
                    <span class="flex-1">
                        <span class="font-bold text-lg font-display">{option.title}</span>
                        <span class="block">
                            {option.description ? option.description : ''}
                        </span>
                    </span>
                    
                        {option.has_scripture_reference ? <Scripture theme={theme} version={option.reference_version} content={option.reference_content} reference={option.scripture_reference}/> : ''}
                </label>
            </div>
           
            </div>
        ))}
        </div>
  </fieldset>
}

export default CheckboxGroup