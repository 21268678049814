const Checkbox = ({name, onChange, value, label}) => {
    return <fieldset class="mb-4">
        <legend className="sr-only">{name}</legend>
        <div className="mt-4">
            <div className="relative flex items-start py-4">
             <div className="ml-3 flex h-5">
                <input
                    id={`${name}`}
                    name={`${name}`}
                    type="checkbox"
                    onClick={onChange}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    checked={value}
                />
            </div>
            <div className="min-w-0 pl-4 text-sm">
                <label htmlFor={`${name}`} className="select-none">
                    <span class="font-medium text-gray-700">{label}</span>
                </label>
            </div>
            </div>
        </div>
  </fieldset>
}

export default Checkbox