import { Disclosure } from '@headlessui/react'
import { FaChevronDown } from 'react-icons/fa';

const AccordionBlock = ({component, theme}) => {
    return <div className="bg-gray-50 mb-8 relative">
      <div class={"w-full h-1 bg-gradient-to-r absolute " + theme.separator_classes}></div>
      <div className="sm:px-6 lg:px-8 pb-6">
        <div className="divide-y-2 divide-gray-200">
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {component.items.map((item) => (
              <Disclosure as="div" key={item.title} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start">
                        <span className="font-bold">{item.title}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <FaChevronDown
                            className={'h-6 w-6 transform ' + (open ? '-rotate-180' : 'rotate-0')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-6 pr-12">
                      <div className="wysiwyg" dangerouslySetInnerHTML={{__html: item.copy}}/>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
}

export default AccordionBlock