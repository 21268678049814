import React, {useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';

import { Button, Input, ListItem, Header, OffsetHeader, UserMenu, CopyBox, SecondaryHeader, Wrapper, PaymentGate} from '../../components'
import themes from '../../constants/themes';
import api from '../../services/api';
import { FaMinusCircle } from "react-icons/fa";

const CourseListLeader = () => {
    const theme = themes['orange'];
    const [courses, setCourses] = useState([])
    const [leaders, setLeaders] = useState([])
    const auth = useContext(AuthContext)

    useEffect(()=>{
        api.getCourses().then((courses)=>{
            setCourses(courses);  
        })
        loadLeaders();

    },[])

    const loadLeaders = () => {
        api.getUsers({role: 'Leader'}).then((leaders)=>{
            setLeaders(leaders);
        })
    }

    const deleteLeader = (id) => {
        api.deleteUserRole(id, 'Leader').then(()=>{
            loadLeaders();
        })
    }

    if (courses.length == 0){
        return null;
    }

    if (!auth.hasRole('Leader')){
        return <CopyBox theme={theme}>
            <div class="mb-4">Logout to proceed.</div>
            <Button size="small" theme={theme} onClick={auth.logout}>Logout</Button>
        </CopyBox>
    }
    return <Wrapper mode="desktop"><PaymentGate>
        <OffsetHeader mode="desktop" expanded={true}>
                    <div class="flex items-center">
                        <div class="flex-1">
                        <div class="mb-4 text-2xl font-bold">{auth.organization.title}</div>

                            <img class="mr-4 w-64 mb-4" src={require('../../images/encircled.png')}/>
                        </div>
                        <UserMenu/>
                    </div>
            </OffsetHeader>
            <CopyBox theme={theme}>
            Welcome to your Encircled dashboard. We're really excited that your partnering with us to get circles of support around your youth. We'll walk you through each step of the way.</CopyBox>

        {courses.map((course)=>{
           return <>
                <ListItem expanded={true}>
            <div class={"h-full w-1 bg-gradient-to-b left-0 absolute " + theme.separator_classes}></div>
                <img src={course.logo.url} class={"ml-4 mr-8 h-12"} />
                <div class="flex-1">
                    <Header size="2xl" classes="flex-1" theme={theme}>{course.title}</Header>
                    <h2 class="text-lg font-light">{course.lessons.length} Sessions</h2>
                </div>



            <Link to={"/course/" + course.slug + "/leader"} class="mr-4"><Button theme={theme}>Dashboard</Button></Link>
        </ListItem>
        <div class="relative">
        <div class="h-1 w-full border-dashed border-t absolute"></div>
            <div class="bg-white -mt-4 mb-4 border-gray-200 border border-t-0 p-8">
            <SecondaryHeader>Invite Leaders</SecondaryHeader>
            <p class="mb-8 text-gray-700 text-sm italic">If you need to give other staff access to the materials for {course.title}, invite them below. If it's just you, visit the dashboard.</p>
            <table class="min-w-full text-left">
                <thead>
                    <tr class="border-b border-gray-200">
                    <th class="uppercase text-sm text-gray-400">First Name</th>
                    <th class="uppercase text-sm text-gray-400">Last Name</th>
                    <th class="uppercase text-sm text-gray-400">Email</th>
                    <th class="uppercase text-sm text-gray-400"></th>
                    </tr>
                </thead>
                {leaders.map((leader)=>{
                    return <tr>
                        <td class="py-4 pr-2">
                            {leader.first_name}
                        </td>
                        <td class="py-4 pr-2">
                            {leader.last_name}
                        </td>
                        <td class="py-4 pr-2">
                            {leader.email}
                        </td>
                        <td class="py-4 pr-2">
                            {auth.user.id !== leader.id ?
                            <button onClick={()=>{deleteLeader(leader.id)}}><FaMinusCircle class="h-5 w-5"/></button> : null}
                        </td>
                    </tr>
                })}
                
                <InviteLeader key={leaders.length + 1} loadLeaders={loadLeaders}/>
            </table>
            </div>
            </div>

            </>
        })}
        </PaymentGate>
    </Wrapper>
}

const InviteLeader = ({loadLeaders}) => {
    const theme = themes['orange'];
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    const invite = () => {
        setError(false);
        setLoading(true);
        api.createUser({
            firstName,
            lastName,
            email,
            role: 'Leader'
        }).then(()=>{
            loadLeaders();
            setLoading(false);
        }).catch((data)=>{
            setError(true);
        })
    }

    return <tr>
            <td class="pr-4">
                <Input name="firstName" onChange={setFirstName}/>
            </td>
            <td class="pr-4">
                <Input  name="lastName" onChange={setLastName}/>
            </td>
            <td class="pr-4">
            < Input name="email" onChange={setEmail}/>
            </td>
            <td>
                <Button loading={loading} onClick={invite} disabled={!firstName || !lastName || !email} size="small" theme={theme}>Invite</Button>
                {error ? <div class="text-xs mt-2 italic">Info was invalid. Please try again.</div>: null}

            </td>
    </tr>
}

export default CourseListLeader