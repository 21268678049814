import { ImageBlock, CopyBlock, QuestionBlock, FeedbackBlock, QuestionResponseBlock, ContributorQuestionBlock, QuoteBlock, AccordionBlock, SectionHeadingBlock, PlanQuestionResponseBlock, PlanCopyBlock, PlanLinkBlock, CtaBlock} from '../';

const Block = ({theme, component}) => {
    switch (component.type) {
        case 'image':
          return <ImageBlock theme={theme} component={component}/>
        case 'copy_block':
            return <CopyBlock theme={theme} component={component}/>
        case 'quote':
            return <QuoteBlock theme={theme} component={component}/>
        case 'accordion':
            return <AccordionBlock theme={theme} component={component}/>
        case 'page_break':
            return null;
        case 'question':
            return <QuestionBlock theme={theme} component={component}/>
        case 'plan_link':
            return <PlanLinkBlock theme={theme} component={component}/>
        case 'question_response':
            return <QuestionResponseBlock theme={theme} component={component}/>
        case 'feedback_block':
            return <FeedbackBlock theme={theme}/>
        case 'cta':
            return <CtaBlock theme={theme} component={component}/>
        case 'contributor_question':
            return <ContributorQuestionBlock theme={theme} component={component}/>
        case 'section_heading':
            return <SectionHeadingBlock theme={theme} component={component}/>
        case 'plan_question_response':
            return <PlanQuestionResponseBlock theme={theme} component={component}/>
        case 'plan_copy_block':
            return <PlanCopyBlock theme={theme} component={component}/>
        }     
 
}

export default Block