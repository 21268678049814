import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import api from '../services/api'
import { Wrapper} from '../components'

const Video = () => {
    const { resourceId } = useParams()
    const [embedCode, setEmbedCode] = useState(null)

    useEffect(()=>{
        api.getLeaderResource(resourceId).then((resource)=>{
            const file = resource.files.find((f)=>{return f.format.value == 'vimeo'});
            if (file && file.embed_code){
                setEmbedCode(file.embed_code);
            }
        })
    },[])

    if (!embedCode){
        return null;
    }

    return <Wrapper mode="desktop">
        <div dangerouslySetInnerHTML={{__html: embedCode}}/>
        </Wrapper>
    }

export default Video