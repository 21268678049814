
import './App.css';

import React, { useContext} from 'react'
import { QR, Login, CourseDetailLeader, LessonDetailLeader, Week1LeaderResponse, Registration, Payment, CourseListLeader, LessonDetailYouth, CourseDetailYouth, CoursePlanYouth, Video, LeaderLogin, ResetPassword, LessonDetailSmallGroupLeader, ForgotPassword} from './pages';
import { Wrapper, OrgSelector} from './components';
import ResponseProvider from './context/ResponseContext';
import AuthProvider from './context/AuthContext';
import { AuthContext } from './context/AuthContext';
import { useNavigate, Navigate, useParams, useSearchParams} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Routes,
  Route,
} from "react-router-dom";
import api from './services/api';
function App() {
  const navigate = useNavigate();

  const wrap = (component, isMobile) => {
    return <Wrapper mode={isMobile ? "mobile": "desktop"}>{component}</Wrapper>
  }

  return (
    <div className="App font-body font-medium bg-gray-100 min-h-screen">
      <AuthProvider>
        <ResponseProvider>
        <ToastContainer/>
          <Routes>
          <Route path="/" element={<Home/>} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route path="/reset/:token" exact element={<ResetPassword />} />
            <Route path="/course/:courseSlug/lesson/:lessonSlug/qr" element={<QR />} />
            <Route path="/course/:slug/welcome" element={<CourseDetailYouth />}/>
            <Route path="/course/:slug/login" element={<Login />}/>
            <Route path="/leader/login" element={<LeaderLogin />}/>
            <Route path="/video/:resourceId" element={<Video />}/>
            <Route path="/course/:courseSlug/lesson/:lessonSlug/youth" element={<YouthOnboard/>}/>
            <Route path="/plan/:planId" element={<Permission type={["Member", "Contributor", "Leader"]}><CoursePlanYouth /></Permission>} />

            <Route path="/course/:courseSlug/leader" element={
              <Permission redirect="/leader/login" type="Leader"><CourseDetailLeader /></Permission>
              }/>
            <Route path="/course/:courseSlug/lesson/:lessonSlug/leader" element={
               <Permission redirect="/leader/login" type="Leader"><LessonDetailLeader /></Permission>
            }/>
            <Route path="/courses" element={
              <Permission redirect="/leader/login" type="Leader">
                <CourseListLeader/>
              </Permission>
            } />
            <Route path="/course/:courseSlug/lesson/:lessonSlug/small-group-leader" element={
               <LessonDetailSmallGroupLeader />
            }/>
            <Route path="/1/leader/response" element={<Week1LeaderResponse/>} />
            <Route path="/register" element={<Registration />} />
            <Route path="/payment" element={<Payment />} />

          </Routes>
        </ResponseProvider>
      </AuthProvider>
    </div>
  );
}

const YouthOnboard= (()=>{
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
   return <Permission redirect={(params)=>{
      navigate( '/course/' + params.courseSlug + '/welcome?lesson=' + params.lessonSlug + '&organization_id=' + searchParams.get('organization_id'));
    }} type="Member">
      <LessonDetailYouth />
    </Permission>
});

const Home = (()=>{
  const auth = useContext(AuthContext)

  const navigate = useNavigate();

  if (!auth.ready){
    return null;
  }
  if (auth.hasRole('Member') && !auth.hasRole('Leader')){
     
  } else {
    navigate('/leader/login');
  }
  return null;
});

const Permission = ({children, type, redirect}) => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate();
  const params = useParams();
  if (!auth.ready){
    return null;
  }
  if (!auth.organization && auth.user){
    return <OrgSelector/>
  }
  if (type!==null && !auth.hasRole(type)){
    if (redirect){
      if (redirect instanceof Function){
        redirect(params);
      } else {
        navigate(redirect);
      }
    } else {
      navigate( '/');
    }
  }
  return children
}

export default App;