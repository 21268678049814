import React from 'react'
import { Link } from 'react-router-dom'

const Payment = () => {
    return <div>
        <Link to="/admin">
        <img src={require('../images/payment.png')}/>
        </Link>
    </div>
}

export default Payment