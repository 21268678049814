import React, {useContext} from 'react'
import { OffsetHeader, CopyBox, UserMenu, Button} from '../components'
import themes from '../constants/themes';

import { AuthContext } from '../context/AuthContext';
import api from '../services/api';

const PaymentGate = ({children}) => {
    const auth = useContext(AuthContext)
    const theme = themes['orange'];
    if (!auth.organization.is_active){
        return <>
            <OffsetHeader mode="desktop" expanded={true}>
                            <div class="flex items-center">
                                <div class="flex-1">
                                <div class="mb-4 text-2xl font-bold">{auth.organization.title}</div>
                                    <img class="mr-4 w-64 mb-4" src={require('../images/encircled.png')}/>
                                </div>
                                <UserMenu/>
                            </div>
                    </OffsetHeader>
            <CopyBox theme={theme}>
                {auth.organization.activated_at ? <div class="mb-4">Your access has expired (after 1 year). In order to have continued access, please renew.</div> : <div class="mb-4">You've registered, but haven't finished making your payment yet.</div>}
                <Button onClick={()=>{
                    api.getOrganization().then((resp)=>{
                        window.location = resp.checkout_url;
                    })
                }} theme={theme}>{auth.organization.activated_at ?'Renew' : 'Pay Now'}</Button>
            </CopyBox>
        </>
    }
    return <>{children}</>
}

export default PaymentGate


