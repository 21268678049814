import React, { useEffect, useState, useContext } from 'react'
import { FaExclamation } from 'react-icons/fa'
import { ResponseContext } from '../../context/ResponseContext'

const PlanQuestionResponseBlock = ({component, theme}) => {
    const response = useContext(ResponseContext)
    const [answers, setAnswers] = useState([])
    useEffect(()=>{
        let newAnswers = '';
        const questions = component.question;
        newAnswers = questions.map((question)=>{
            let newResponse = response.getAnswerByQuestionId(question.id);
            if (!newResponse){
                return null;
            }
            let supplementaries = response.getSupplementariesByQuestionId(question.id);
            return newResponse.length > 1 ? 
                <div class="wysiwyg"><ul>{newResponse.map((answer, key)=>{
                return <li>{answer} {supplementaries && supplementaries[key] ? <div class="text-xs italic">{supplementaries[key]}</div> : ''}</li>
            })}</ul></div>
            : <ListItem multipleQuestions={questions.length > 1}>{newResponse[0]} {supplementaries && supplementaries[0] ? <div class="text-xs italic">{supplementaries[0]}</div> : ''}</ListItem>;
        })
        newAnswers = newAnswers.filter((answer)=>answer!=null)
        setAnswers(newAnswers);
    },[response.answers])
    
   
    return <div class="mb-4">
            <div class="font-bold pb-2 mb-2">{component.plan_intro}</div>
            <ListItemWrapper multipleQuestions={component.question.length > 1}>
                { answers.length == 0  ? <div class="flex"><FaExclamation class="h-5 w-5 pr-2 text-gray-400"/> The youth didn't answer this question or made their answer private.</div>
                : answers.map((answer, key)=>{
                    return typeof answer == 'object' ? answer : (key!== 0 ? ' ' : '') + answer;
                }) }
            </ListItemWrapper>
    </div>
}

const ListItem = ({multipleQuestions, children}) => {
    if (multipleQuestions){
        return <li>{children}</li>
    }
    return <div>{children}</div>
}

const ListItemWrapper = ({multipleQuestions, children}) => {
    if (multipleQuestions){
        return <div class="wysiwyg bg-gray-50 p-4"><ul>{children}</ul></div>
    }
    return <div class="bg-gray-50 p-4">{children}</div>
}

export default PlanQuestionResponseBlock