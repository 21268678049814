import React, { useEffect, useState, useContext } from 'react'
import { QuestionBox, Question, TextArea, CheckboxGroup, Select, ReportRecipients, Checkbox } from '../../components';
import api from '../../services/api'
import { ResponseContext } from '../../context/ResponseContext'

const QuestionBlock = ({component, theme}) => {
    const [question, setQuestion] = useState(0)
    const response = useContext(ResponseContext)
    const [visible, setVisible] = useState(response.answers[component.question.id] && response.answers[component.question.id].hasOwnProperty('visible') ? response.answers[component.question.id].visible : true)

    const input = (question) => {
        const answer = response.getAnswerByQuestionId(question.id);
        switch (question.type.value) {
            case 'checkbox':
              return <CheckboxGroup theme={theme} name={question.id} value={answer ? answer: []} 
              onChange={(option)=>{
                    response.toggleMany(question, option.title, visible, option);
                    response.toggleFeedback(option.feedback);
              }} options={question.options}/>
            case 'textarea':
                return <TextArea defaultValue={answer} onChange={(value)=>{response.updateSingle(question.id, value, visible)}} />
            case 'select':
                return <Select defaultValue={answer} onChange={(option)=>{response.updateSingle(question.id, option.title, visible)}} name={question.id} options={question.options}/>
            case 'custom':
                if (question.custom_type =='report_recipients'){
                    return <ReportRecipients theme={theme} />            
                }
            }          
    }

    useEffect(()=>{
        api.getQuestion(component.question.id).then((question)=>{
            if (question.required_minimum){
                response.setNextReady(false);
            }
            setQuestion(question);
        })
    },[])

    useEffect(()=>{
        response.updateVisible(question.id, visible);
    },[visible])
    
    if (!question){
        return null;
    }
    return <QuestionBox theme={theme}>
    <Question theme={theme} title={question.title} requiredMinimum={question.required_minimum} requiredMaximum={question.required_maximum}  subtitle={question.helper_text}/>
    {input(question)}
    
    {question.type.value !='custom' ? <Checkbox name="visible" label="Share this answer with my circle on my vision plan" value={visible} onChange={()=>{setVisible(!visible)}} /> : null}
</QuestionBox>
}

export default QuestionBlock