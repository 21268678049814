import React, { useContext } from 'react'
import { ResponseContext } from '../../context/ResponseContext'
import { FeedbackItem } from '../../components'
const FeedbackBlock = ({theme}) => {
    const response = useContext(ResponseContext)
    return <div>
        {Object.keys(response.feedbacks).map(function(key, index) {
            const feedback = response.feedbacks[key];
            return <FeedbackItem theme={theme} id={feedback.id}/>
        })}
    </div>
}

export default FeedbackBlock