const themes = {
    orange: {
        'text_classes': "text-orange-600",
        'button_classes': "border border-orange-600 text-orange-600",
        'checkbox_classes': "focus:ring-orange-600" + " text-orange-600",
        'separator_classes': "from-orange-300 to-orange-600",
        'icon_background_classes': "bg-orange-600",
    },
    blue: {
        'text_classes': "text-blue-600",
        'button_classes': "border border-blue-600 text-blue-600",
        'checkbox_classes': "focus:ring-blue-600" + " text-blue-600",
        'separator_classes': "from-blue-300 to-blue-600",
        'icon_background_classes': "bg-blue-600"
    },
    pink: {
        'text_classes': "text-pink-600",
        'button_classes': "border border-pink-600 text-pink-600",
        'checkbox_classes': "focus:ring-pink-600" + " text-pink-600",
        'separator_classes': "from-pink-300 to-pink-600",
        'icon_background_classes': "bg-pink-600"
    },
    green: {
        'text_classes': "text-green-600",
        'button_classes': "border border-green-600 text-green-600",
        'checkbox_classes': "focus:ring-green-600" + " text-green-600",
        'separator_classes': "from-green-300 to-green-600",
        'icon_background_classes': "bg-green-600"
    },
    black: {
        'text_classes': "text-black",
        'button_classes': "border border-black text-black",
        'checkbox_classes': "focus:ring-black" + " text-black",
        'separator_classes': "from-black to-black",
        'icon_background_classes': "bg-black"
    }
}

export default themes;