import React, {useEffect, useState} from 'react'
import { Input, Wrapper, OffsetHeader, CopyBox, Button } from '../components'
import themes from '../constants/themes';
import api from '../services/api';
import * as Yup from "yup";
import { useForm } from "react-yup";
import { useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';

const schema = Yup.object({
    email: Yup.string().email().required(),
    token: Yup.string().required().min(1),
    password: Yup.string().required().min(8).max(100).matches(
        /[@$!%*#?&]+/,
      'Need one special character',
    ).matches(/[a-z]+/, "Need one lowercase character"),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
  }).defined();

const ResetPassword = () => {
    const [serverError, setServerError] = useState(false)
    const theme = themes['orange'];
    const navigate = useNavigate();    

    const { token } = useParams();
    let [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const {
        values,
        touched,
        errors,
        field,
        createSubmitHandler,
        setValue
      } = useForm({
        validationSchema: schema,
      });

    const handleSubmit = React.useMemo(() => {
        return createSubmitHandler(
            (values) => {
                setServerError(false);
                api.resetPassword(values).then(()=>{
                    toast.success('Password Set.');
                    navigate('/leader/login');
                }).catch(()=>{
                    setServerError(true);
                })
            });
    }, []);

    useEffect(()=> {
        setValue('token', token);
    }, [token]);

    useEffect(()=> {
        setValue('email', searchParams.get('email'));
    }, [email]);

    return <Wrapper mode="desktop">

        <OffsetHeader mode="desktop" expanded={true}>
                    <div class="flex items-center">
                        <div class="flex-1">
                            <img class="mr-4 w-64" src={require('../images/encircled.png')}/>
                        </div>
                    </div>
            </OffsetHeader>
            <CopyBox theme={theme}>
                        {!searchParams.get('invited') ? 'Reset your password below' : 'Create your password for Encircled below'}
                    </CopyBox>
                    <div class="max-w-lg">
                    <form onSubmit={handleSubmit}>

                    <CopyBox theme={theme}>
                        {serverError ? <div class="text-xs my-2 italic">There was an error. Please try again.</div> : null}

                        <Input type="password" name="password" label={searchParams.get('invited') ? "Create a password to get started" : "New Password" } field={field}/>
                        {errors.password && touched.password && <div class="text-xs my-2 italic">{errors.password}</div>}
                        <Input type="password" name="password_confirmation" label="Confirm Password" field={field}/>    
                        {errors.password_confirmation && touched.password_confirmation && <div class="text-xs my-2 italic">{errors.password_confirmation}</div>}

                        <div className="flex-1 mr-2">
                        <div>
                            <div class="text-cool-gray-500">
                                <div class="text-sm mb-2">Ensure that these requirements are met:</div>
                                <ul class="ml-4">
                                    <li class="mb-1 text-xs">At least 10 characters (and up to 100 characters)</li>
                                    <li class="mb-1 text-xs">At least one lowercase character</li>
                                    <li class="text-xs">Inclusion of at least one special character, e.g., @ $ ! % * # ?&</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </CopyBox>
                    <Button theme={theme}>{searchParams.get('invited') ? 'Set Password' : 'Reset Password'}</Button>
                </form>
        </div>
    </Wrapper>
    }

export default ResetPassword