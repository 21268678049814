import React, { useEffect, useState, useContext } from 'react'
import api from '../../services/api'
import { useParams, useSearchParams } from 'react-router-dom'
import { ResponseContext } from '../../context/ResponseContext'

import {  LessonHeader, Pagination, Block, Wrapper, Button, CopyBox } from '../../components';
import { AuthContext } from '../../context/AuthContext';

const Lesson = () => {
    const [lesson, setLesson] = useState(null)
    const [course, setCourse] = useState(null)

    const [page, setPage] = useState(0)
    const [pages, setPages] = useState([])
    const [invalidLoggedIn, setInvalidLoggedIn] = useState(false)
    let [searchParams] = useSearchParams();

    const { courseSlug, lessonSlug } = useParams()
    const auth = useContext(AuthContext)

    const response = useContext(ResponseContext)

    useEffect(()=>{
        api.getLesson(lessonSlug).then((lesson)=>{
            setLesson(lesson);  
        })
        api.getCourse(courseSlug).then((course)=>{
            setCourse(course);  
            response.loadPlan(course.id);
        })
    },[])

    useEffect(()=>{
        // They're a Member, but for a different org.
        if (auth.organization && searchParams.get('organization_id') && (parseInt(searchParams.get('organization_id')) !== parseInt(auth.organization.id))){
            setInvalidLoggedIn(true);
        }
    }, [auth.organization])

    useEffect(()=>{
        if (lesson){
            let activePages = [];
            let activePage = [];
            lesson.components.forEach((component)=>{
                if (!component.question || !component.question.show_if || response.hasAnswer(component.question.show_if_question_id, component.question.show_if_value)){
                    if (component.type == 'page_break'){
                        if (activePage.length > 0){
                            activePages.push(activePage);
                            activePage = [];    
                        }
                    } else {
                        activePage.push(component);
                    }
                }
            })
            activePages.push(activePage);
            setPages(activePages);
        } else {
            setPages([]);
        }
    },[lesson, response.answers])

    if (!lesson || !course || !response.plan){
        return null;
    }
    if (invalidLoggedIn){
        return <CopyBox theme={lesson.theme}>
            <div class="mb-4">You're currently logged into a different organization. Logout to proceed.</div>
            <Button size="small" theme={lesson.theme} onClick={auth.logout}>Logout</Button>
        </CopyBox>
    }
    return <Wrapper mode="mobile">
         <LessonHeader mode="mobile" lesson={lesson} page={page} totalPages={pages.length} userName={auth.user.first_name}/>
        {pages.map((p, index)=>{
            return index == page ? p.map((component)=>{
                return <div><Block theme={lesson.theme} component={component}/></div>
            }) : null;
        })}
       <Pagination onComplete={()=>{response.onComplete(course, lesson)}} nextNotReadyText={response.nextNotReadyText} nextReady={response.nextReady} theme={lesson.theme} page={page} setPage={setPage} totalPages={pages.length}/>
        <div class="text-center text-sm pb-8">
            Contact us at <a href="mailto:info@encircled.org" class="underline">info@encircled.org</a> with any issues or ideas<span onClick={auth.logout}>.</span>
        </div>
    </Wrapper>
}

export default Lesson