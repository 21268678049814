import React, { useEffect, useState, useContext } from 'react'
import { ResponseContext } from '../../context/ResponseContext'

const QuestionResponseBlock = ({component, theme}) => {
    const response = useContext(ResponseContext)
    const [answers, setAnswers] = useState(null)
    useEffect(()=>{
        setAnswers(response.getAnswerByQuestionId(component.question.id));
    },[response.answers])
    
    if (!answers){
        return null;
    }

    return <div>
            <div class="mb-6 text-sm text-justify bg-white p-6 text-gray-500">
                <div class="font-bold pb-2 mb-2 border-b">{component.question.title}</div>
                {Array.isArray(answers) ? answers.map((answer)=>{
                    return <div>{answer}</div>
                }): <div>{answers}</div>}
            </div>
    </div>
}

export default QuestionResponseBlock