import React, { useEffect, useState, useContext} from 'react'
import api from '../../services/api'

import { useParams, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';

import { Button, OffsetHeader, Block, Wrapper, CopyBox } from '../../components'
import {
    Link
  } from "react-router-dom";
 
  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const CourseDetailYouth = () => {
    const { slug } = useParams()
    const [course, setCourse] = useState(null)
    const [lesson, setLesson] = useState(null)
    const auth = useContext(AuthContext)

    let query = useQuery();

    useEffect(()=>{
        api.getCourse(slug).then((course)=>{
            setCourse(course);  
        })
        api.getLesson(query.get('lesson')).then((lesson)=>{
            setLesson(lesson);  
        })
    },[])

    if (!course || !lesson){
        return null;
    }

    if (auth.user){
        return <CopyBox theme={lesson.theme}>
            <div class="mb-4">Logout to proceed.</div>
            <Button size="small" theme={lesson.theme} onClick={auth.logout}>Logout</Button>
        </CopyBox>
    }
    return <Wrapper mode="mobile">
        <OffsetHeader mode="mobile" expanded={true}>
                <div class="flex items-center">
                    <div class="flex-1">
                        <img class="mr-4 w-64" src={course.logo.url}/>
                    </div>
                </div>
        </OffsetHeader>
        {course.intro_components.map((component)=>{
            return <div><Block theme={lesson.theme} component={component}/></div>
        })}
        <div class="flex justify-center pb-8">
            <Link to={"/course/" + slug + "/login?lesson=" + query.get('lesson') + '&organization_id=' + query.get('organization_id')}><Button theme={lesson.theme}>Get Started</Button></Link>
        </div>
    </Wrapper>
}

export default CourseDetailYouth