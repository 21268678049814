import React, {useState, useEffect, useContext} from 'react'

import { Link } from 'react-router-dom'

import { Button, Header, ListItem } from '.'
import api from '../services/api'
import { AuthContext } from '../context/AuthContext';

const CourseLesson = ({course, id}) => {
    const [lesson, setLesson] = useState(null)
    const auth = useContext(AuthContext)

    useEffect(()=>{
        api.getLessonById(id).then((lesson)=>{
            setLesson(lesson); 
        })
    },[])

    if (!lesson){
        return null;
    }

    return <ListItem>
        <div class={"h-full w-1 bg-gradient-to-b left-0 absolute " + lesson.theme.separator_classes}></div>
        <img class="mr-4 w-16" src={lesson.icon.url}/>
        <div class="flex-1">
            <Header size="2xl" classes="flex-1" theme={lesson.theme}>{lesson.title}</Header>
            <h2 class="text-lg font-light">Session {lesson.number}</h2>
        </div>
        <Link target="_blank" to={"/course/" + course.slug + "/lesson/" + lesson.slug + "/leader"} class="mr-3"><Button size="small" theme={lesson.theme}>Materials</Button></Link>
        {lesson.featured_video ? <Link target="_blank" to={"/video/" + lesson.featured_video.id} class="mr-3"><Button size="small" theme={lesson.theme}>Video</Button></Link> : null}
        <Link target="_blank" to={"/course/" + course.slug + "/lesson/" + lesson.slug + "/qr?organization_id=" + auth.organization.id}><Button size="small" theme={lesson.theme}>Start Discussion</Button></Link>
    </ListItem>
}

export default CourseLesson