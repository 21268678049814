import React, { useEffect } from 'react'

const Select = ({label, name, options, onChange, after, field, defaultValue}) => {

    useEffect(()=>{
       if (!defaultValue){
        onChange(options[0]);
       }
    }, [])

    return <div>
    { label ? <label htmlFor="location" className="block text-sm font-medium text-gray-700">
      {label}
    </label> : null }
    <div class="flex items-center">
    <select
      id={name}
      defaultValue={defaultValue ? defaultValue : null}
      onChange={(e)=>{
        let option = options.find((opt)=>opt.title == e.target.value);
        onChange(option);
      }}
      {...field}
      name={name}
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    >
    {options.map((option)=>{
        return <option id={option.title}>{option.title}</option>
    })}
    </select>
    {after ? after : null}
    </div>
  </div>
}

export default Select