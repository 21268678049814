import React, { useEffect, useState } from 'react'
import api from '../services/api'
import { FaArrowRight } from "react-icons/fa";

const ResourceItem = ({id, theme}) => {
    const [resource, setResource] = useState(null)

    useEffect(()=>{
        api.getStudentResource(id).then((resource)=>{
            setResource(resource);
        })
    },[])
    if (!resource){
        return null;
    }
    return <a class="flex align-middle border" target="_blank" href={resource.link}>
       <span class={"px-3 flex items-center " + theme.icon_background_classes}>
           <FaArrowRight class="h-5 w-5 text-white"/>
        </span>
        <span class="p-4">
            <span>{resource.title}</span>
            <span class="italic text-xs text-gray-600 block">External Site</span>
        </span>
    </a>
}

export default ResourceItem