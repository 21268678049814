const Input = ({label, name, placeholder, labelClasses, inputClasses, onChange, type, field, autocomplete}) => {
    if (!labelClasses){
      labelClasses = "";
    }
    if (!inputClasses){
      inputClasses = "";
    }
    if (!type){
      type = "text";
    }
    if (!autocomplete){
      autocomplete="";
    }
  
    return <div class="mb-4">
    <label htmlFor={name} className={"block text-sm font-medium text-gray-700 mb-4 " + labelClasses}>
      {label}
    </label>
    <div className="mt-1">
      <input
        autocomplete={autocomplete}
        type={type}
        name={name}
        id={name}
        onChange={(e)=>onChange ? onChange(e.target.value): null}
        className={"py-3 px-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 " + inputClasses}
        placeholder={placeholder}
        {...field}
      />
    </div>
  </div>
}

export default Input
