const PlanCopyBlock = ({component, theme}) => {
    return <div class="mb-8 relative">
        {component.title ? 
            <div>
                <div class="mb-6"><h2 class="uppercase text-2xl font-bold text-black">{component.title}</h2>
                <div class={"w-full h-1 bg-gradient-to-r absolute " + theme.separator_classes}></div>
            </div></div>
        
        : null}
        <div dangerouslySetInnerHTML={{__html: component.copy}}/>
    </div>
}

export default PlanCopyBlock