import React, { useEffect, useState } from 'react'
import api from '../../services/api'
import { useParams } from 'react-router-dom'

import {  LessonHeader, Pagination, Block, Wrapper } from '../../components';

const LessonDetailSmallGroupLeader = () => {
    const [lesson, setLesson] = useState(null)

    const [page, setPage] = useState(0)
    const [pages, setPages] = useState([])
    const { lessonSlug } = useParams()

    useEffect(()=>{
        api.getLesson(lessonSlug).then((lesson)=>{
            setLesson(lesson);  
        })
    },[])

    useEffect(()=>{
        if (lesson){
            let activePages = [];
            let activePage = [];
            lesson.small_group_components.forEach((component)=>{
                if (component.type == 'page_break'){
                    if (activePage.length > 0){
                        activePages.push(activePage);
                        activePage = [];    
                    }
                } else {
                    activePage.push(component);
                }
            })
            activePages.push(activePage);
            setPages(activePages);
        } else {
            setPages([]);
        }
    },[lesson])

    if (!lesson){
        return null;
    }
    return <Wrapper mode="mobile">
         <LessonHeader mode="mobile" lesson={lesson} page={page} totalPages={pages.length} userName="Small Group Leader"/>
        {pages.map((p, index)=>{
            return index == page ? p.map((component)=>{
                return <div><Block theme={lesson.theme} component={component}/></div>
            }) : null;
        })}
       <Pagination onComplete={()=>{}} nextReady={true} theme={lesson.theme} page={page} setPage={setPage} totalPages={pages.length}/>
    </Wrapper>
}

export default LessonDetailSmallGroupLeader