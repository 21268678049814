import React, { createContext, useMemo, useState } from 'react'
import api from '../services/api'
export const ResponseContext = createContext({})

const ResponseProvider = (props) => {
  const { children } = props
  const [answers, setAnswers] = useState({})
  const [contributorAnswers, setContributorAnswers] = useState({})
  const [nextReady, setNextReady] = useState(true)
  const [nextNotReadyText, setNextNotReadyTest] = useState('')
  const [feedbacks, setFeedbacks] = useState({})
  const [plan, setPlan] = useState(null)
  const loadPlan = (courseId)=>{
    api.getPlans({courseId}).then((plans)=>{
      if (plans.length > 0){
        setPlan(plans[0]);
      } else {
        api.createPlan({courseId}).then((plan)=>{
          setPlan(plan);

        })
      }
    })
  }

  const populate = (plan) => {
      setPlan(plan);
      let answers = {};
      plan.lesson_responses.forEach((lesson_response)=>{
          lesson_response.lesson_response_questions.forEach((lesson_response_question)=>{
              answers[lesson_response_question.question_id] = {
                values: lesson_response_question.lesson_response_answers.map((lesson_response_answer)=>{
                  return lesson_response_answer.content;
               }),
               supplementaries: lesson_response_question.lesson_response_answers.map((lesson_response_answer)=>{
                return lesson_response_answer.supplementary;
              }),
              };
          })
      })
      setAnswers(answers);

      let contributorAnswers = {};
      plan.plan_feedback.forEach((userFeedback)=>{
        if (contributorAnswers[userFeedback.question_id]){
          contributorAnswers[userFeedback.question_id].push(userFeedback);
        } else {
          contributorAnswers[userFeedback.question_id] = [userFeedback];
        }
      })
      setContributorAnswers(contributorAnswers)
  }

  const toggleMany = (question, value, visible, option) => {
    let questionId = question.id;
    let newAnswers = {...answers};
    let supplementary = option.has_scripture_reference ? option.reference_content + ' ' + option.scripture_reference + ' ' +  option.reference_version : null;
    if (newAnswers[questionId]){
      let answerIndex = newAnswers[questionId].values.indexOf(value)
      if (answerIndex > -1){
        newAnswers[questionId].values.splice(answerIndex, 1);
        newAnswers[questionId].supplementaries.splice(answerIndex, 1);
        if (newAnswers[questionId].values.length == 0){
          delete newAnswers[questionId];
        } else {
          newAnswers[questionId].visible = visible;
        }
      } else {
        newAnswers[questionId].values.push(value);
        newAnswers[questionId].supplementaries.push(supplementary);
        newAnswers[questionId].visible = visible;
      }
    } else {
      newAnswers[questionId] = {
        visible,
        values: [value],
        supplementaries: [supplementary]
      }
    }
    updateNextReady(newAnswers, question);
    setAnswers(newAnswers);
  }

  const onComplete = (course, lesson) => {
    api.createLessonResponse(
      {
        course_id: course.id,
        lesson_id: lesson.id,
        items: Object.keys(answers).map((questionId)=>{
          return {
            'question_id': questionId,
            'data': Array.isArray(answers[questionId].values) ? answers[questionId].values : [answers[questionId].values],
            'visible': answers[questionId].visible,
            'supplimentaries': answers[questionId].supplementaries,
          }
      }
    )});
  }

  const updateNextReady = (answers, question)=>{
    let newNextReady = true;
    let newNextNotReadyTest = '';
    if ((question.required_minimum && (!answers[question.id] || (answers[question.id].values.length < question.required_minimum))
    || (question.required_maximum && (!answers[question.id]  || answers[question.id].values.length > question.required_maximum)))){
      newNextReady= false;
      newNextNotReadyTest+= 'You need to select between ' + question.required_minimum + ' and ' + question.required_maximum + ' options for this question: ' +  question.title + '<br/>';
    }
    setNextNotReadyTest(newNextNotReadyTest);
    setNextReady(newNextReady)
  }

  const updateVisible = (questionId, visible) => {
    if (answers[questionId]){
      let newAnswers = {...answers};
      newAnswers[questionId].visible = visible;
      setAnswers(newAnswers);
    }
  }

  const updateSingle = (questionId, value, visible) => {
    let newAnswers = {...answers};
    if (value){
      newAnswers[questionId] = {
        visible,
        values: value,
        supplementaries: null
      };
    } else if (questionId in newAnswers){
        delete newAnswers[questionId];
    }
    setAnswers(newAnswers);
  }

  const toggleFeedback = (feedback) => {
    let newFeedbacks = {...feedbacks};
    if (newFeedbacks[feedback.id]){
      const feedbackId = feedback.id;
      delete newFeedbacks[feedbackId];
    } else {
      newFeedbacks[feedback.id] = feedback;
    }
    setFeedbacks(newFeedbacks);
  }

  const hasAnswer = (questionId, value)=> {
    if (!answers[questionId]){
      return false;
    } 
    if (!value){
      return true;
    }
    if (Array.isArray(answers[questionId].values)){
      return answers[questionId].values.indexOf(value) > -1;
    } else {
      return answers[questionId].values ==value;
    }
  }

  const getAnswerByQuestionId = (questionId) => {
    return answers[questionId] ? answers[questionId].values : null;
  }

  const getSupplementariesByQuestionId = (questionId) => {
    return answers[questionId] ? answers[questionId].supplementaries : null;
  }
  
  const getContributorAnswerByQuestionId = (questionId) => {
    return contributorAnswers[questionId] ? contributorAnswers[questionId] : null;
  }

  const getContributorHasAnsweredQuestion = (user, questionId) => {
    return contributorAnswers[questionId] ? contributorAnswers[questionId].find((contributorAnswer)=>{
     return contributorAnswer.user.id == user.id;
    }) : false;
  }

  const value = useMemo(() => {
    return {
      onComplete,
      updateSingle,
      answers,
      feedbacks,
      nextReady,
      nextNotReadyText,
      setNextReady,
      hasAnswer,
      toggleFeedback,
      toggleMany,
      getAnswerByQuestionId,
      getContributorAnswerByQuestionId,
      populate,
      loadPlan,
      updateVisible,
      plan,
      contributorAnswers,
      getContributorHasAnsweredQuestion,
      getSupplementariesByQuestionId
    }
  }, [answers, plan, feedbacks, nextReady, nextNotReadyText, contributorAnswers])

  return (
    <ResponseContext.Provider value={value} {...props}>
      {children}
    </ResponseContext.Provider>
  )
}

export default ResponseProvider
