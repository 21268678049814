import { Wrapper } from '../components';


const OffsetHeader = ({children, expanded, mode}) => {
    return <div class={"bg-gradient-to-b from-gray-100 to-gray-200 " + (expanded ? "pt-8 pb-32 -mb-24" : "py-2") + (mode =="desktop" ? " breakout": " breakout-mobile")}>
        <Wrapper mode={mode}>
            {children}
        </Wrapper>
    </div>
}
export default OffsetHeader

